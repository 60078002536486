import React from 'react';
import ReactFlow, { Background, Controls, Node, Edge } from 'reactflow';
import 'reactflow/dist/style.css';

const NODE_WIDTH = 200;
const NODE_HEIGHT = 80;
const X_SPACING = 300; // Horizontal spacing between nodes
const Y_SPACING = 200; // Vertical spacing between levels

interface CustomNode extends Node {
  position: { x: number; y: number };
}

const UsageTree: React.FC = () => {
  const edges: Edge[] = [
    { id: 'Home-DailyInfoScreen', source: 'Home', target: 'DailyInfoScreen' },
    { id: 'Home-Trends', source: 'Home', target: 'Trends' },
    { id: 'Home-Settings', source: 'Home', target: 'Settings' },
    { id: 'Home-Hive', source: 'Home', target: 'Hive' },

    // Settings child nodes
    { id: 'Settings-ProfileScreen', source: 'Settings', target: 'ProfileScreen' },
    { id: 'Settings-PreferencesScreen', source: 'Settings', target: 'PreferencesScreen' },
    { id: 'Settings-ImportScreen', source: 'Settings', target: 'ImportScreen' },
    { id: 'Settings-ExportScreen', source: 'Settings', target: 'ExportScreen' },
    { id: 'Settings-AboutScreen', source: 'Settings', target: 'AboutScreen' },

    // Hive child nodes
    { id: 'Hive-HiveUserPage', source: 'Hive', target: 'HiveUserPage' },
    { id: 'Hive-HiveCommunityScreen', source: 'Hive', target: 'HiveCommunityScreen' },
    { id: 'Hive-HiveFollowersScreen', source: 'Hive', target: 'HiveFollowersScreen' },
    { id: 'Hive-HiveNotificationScreen', source: 'Hive', target: 'HiveNotificationScreen' },
    { id: 'Hive-HivePostsScreen', source: 'Hive', target: 'HivePostsScreen' },
    { id: 'Hive-HiveProfileScreen', source: 'Hive', target: 'HiveProfileScreen' },
    { id: 'Hive-HiveSearchScreen', source: 'Hive', target: 'HiveSearchScreen' },
    { id: 'Hive-HiveNewPostScreen', source: 'Hive', target: 'HiveNewPostScreen' },
  ];

  const nodes: CustomNode[] = [
    { id: 'Home', data: { label: 'Home (Count: 0)' }, position: { x: 0, y: 0 }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'DailyInfoScreen', data: { label: 'DailyInfoScreen (Count: 0)' }, position: { x: -X_SPACING, y: Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'Trends', data: { label: 'Trends (Count: 0)' }, position: { x: -2 * X_SPACING, y: Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'Settings', data: { label: 'Settings (Count: 0)' }, position: { x: 0, y: Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'Hive', data: { label: 'Hive (Count: 0)' }, position: { x: 2 * X_SPACING, y: Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },

    // Settings child nodes with calculated positions
    { id: 'ProfileScreen', data: { label: 'ProfileScreen (Count: 0)' }, position: { x: -2 * X_SPACING, y: 2 * Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'PreferencesScreen', data: { label: 'PreferencesScreen (Count: 0)' }, position: { x: -X_SPACING, y: 2 * Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'ImportScreen', data: { label: 'ImportScreen (Count: 0)' }, position: { x: 0, y: 2 * Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'ExportScreen', data: { label: 'ExportScreen (Count: 0)' }, position: { x: X_SPACING, y: 2 * Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'AboutScreen', data: { label: 'AboutScreen (Count: 0)' }, position: { x: 2 * X_SPACING, y: 2 * Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },

    // Hive child nodes with calculated positions
    { id: 'HiveUserPage', data: { label: 'HiveUserPage (Count: 0)' }, position: { x: -3 * X_SPACING, y: 3 * Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'HiveCommunityScreen', data: { label: 'HiveCommunityScreen (Count: 0)' }, position: { x: -2 * X_SPACING, y: 3 * Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'HiveFollowersScreen', data: { label: 'HiveFollowersScreen (Count: 0)' }, position: { x: -X_SPACING, y: 3 * Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'HiveNotificationScreen', data: { label: 'HiveNotificationScreen (Count: 0)' }, position: { x: 0, y: 3 * Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'HivePostsScreen', data: { label: 'HivePostsScreen (Count: 0)' }, position: { x: X_SPACING, y: 3 * Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'HiveProfileScreen', data: { label: 'HiveProfileScreen (Count: 0)' }, position: { x: 2 * X_SPACING, y: 3 * Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'HiveSearchScreen', data: { label: 'HiveSearchScreen (Count: 0)' }, position: { x: 3 * X_SPACING, y: 3 * Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
    { id: 'HiveNewPostScreen', data: { label: 'HiveNewPostScreen (Count: 0)' }, position: { x: 4 * X_SPACING, y: 3 * Y_SPACING }, style: { width: NODE_WIDTH, height: NODE_HEIGHT } },
  ];

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <ReactFlow nodes={nodes} edges={edges} fitView>
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default UsageTree;
