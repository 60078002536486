import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { isAuthenticated, verifyToken } from '../../auth/helpers';
import { getUsage, getUsers } from '../../api/helpers';
import { Navigate } from 'react-router-dom';
import { UsageObject, UserType } from '../../Types';
import UsageData from '../../components/usageData';
import UserList from '../../components/userList';
import { GradientCircularProgress } from '../../components/loadingComponents';

type UsageObjectSetter = React.Dispatch<React.SetStateAction<UsageObject | null>>;
type UserObjectSetter = React.Dispatch<React.SetStateAction<UserType[]>>;

const loadUsageItems = async (email: string, pageNum: number, setUsageItems: UsageObjectSetter) => {
    const data = await getUsage(email, pageNum);
    if (!data) {
        return;
    }
    setUsageItems((prev) => {
        return {
            ...prev,
            [email]: data,
        };
    });
};

const loadUsers = async (pageNum: number, setUsers: UserObjectSetter, existingUsers: UserType[]) => {
    const data = await getUsers(pageNum);
    if (!data) {
        return;
    }

    // console.log('API Response for page', pageNum, ':', data); // Check if data is correct

    const users = data['users'];
    const uniqueUsers = users.filter(
        (newUser: UserType) => !existingUsers.some((existingUser) => existingUser.email === newUser.email)
    );

    // console.log('Unique users for page', pageNum, ':', uniqueUsers); // Check filtered unique users

    setUsers((prev) => {
        const updatedUsers = [...prev, ...uniqueUsers];
        console.log('Updated users state:', updatedUsers); // Check final updated users state
        return updatedUsers;
    });
};


export const Users = () => {
    const initialized = useRef(false);
    const [redirect, setRedirect] = useState(false);
    const [usageResponse, setUsageResponse] = useState<UsageObject | null>(null);
    const [users, setUsers] = useState<UserType[]>([]);
    const [currentPageNum, setCurrentPageNum] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Keep track of total pages from API
    const [userDataLoading, setUserDataLoading] = useState(false);

    useEffect(() => {
        if (currentPageNum > totalPages) {
            // console.log('No more pages to load.');
            return;
        }
        if (currentPageNum > 1) {
            // console.log(`Loading users for page: ${currentPageNum}`);
            loadUsers(currentPageNum, setUsers, users);
        }
    }, [currentPageNum]);

    useEffect(() => {
        if (initialized.current) {
            return;
        }
        initialized.current = true;

        const authStatus = isAuthenticated();
        if (!authStatus) {
            setRedirect(true);
            return;
        }

        console.log('Checking token...');
        verifyToken()
            .then((status) => {
                if (status) {
                    getUsers(1).then((data) => {
                        if (data) {
                            const { users: initialUsers, total_pages } = data;
                            setUsers(initialUsers);
                            setTotalPages(total_pages); // Set the total pages
                        }
                    });
                } else {
                    setRedirect(true);
                }
            })
            .catch((error) => {
                console.error('Token verification failed:', error);
                setRedirect(true);
            });
    }, []);

    useEffect(() => {
        if (!usageResponse) {
            return;
        }
        setUserDataLoading(false);
    }, [usageResponse]);

    const [selectedUser, setSelectedUser] = useState('');

    const handleUserSelect = (userId: string) => {
        setSelectedUser(userId);
    };

    useEffect(() => {
        if (!selectedUser) {
            return;
        }
        if (!usageResponse || !usageResponse[selectedUser]) {
            setUserDataLoading(true);
            loadUsageItems(selectedUser, 1, setUsageResponse);
        }
    }, [selectedUser, usageResponse]);

    const handleLoadMore = () => {
        setCurrentPageNum((prevPage) => prevPage + 1);
    };

    return redirect ? (
        <Navigate to={{ pathname: '/login' }} />
    ) : users.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <GradientCircularProgress />
            <Typography variant="h5" ml={3}>
                Loading users...
            </Typography>
        </Box>
    ) : (
        <Container
            maxWidth={false}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                minHeight: '100vh',
                flex: 1,
            }}
        >
            {/* </Box> */}
            <Box sx={{ display: 'flex', width: '80%', flexDirection: 'row' }}>
                <Box sx={{ display: 'flex', flex: 2, flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', pl: 2, pr: 2 }}>
                        {users.length > 0 && (
                            <UserList
                            users={users} // Ensure this prop reflects the updated state
                            selectedUser={selectedUser}
                            onUserSelect={handleUserSelect}
                            handleLoadMore={handleLoadMore}
                        />
                        
                        )}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flex: 8, ml: 2 }}>
                    {selectedUser && userDataLoading && <GradientCircularProgress />}
                    {selectedUser && !userDataLoading && usageResponse && usageResponse[selectedUser] && (
                        <UsageData usages={usageResponse[selectedUser].usages} total_pages={usageResponse[selectedUser].total_pages} />
                    )}
                    {!selectedUser && (
                        <Typography variant="h5" gutterBottom>
                            Select a user to view their data
                        </Typography>
                    )}
                </Box>
            </Box>
        </Container>
    );
};
